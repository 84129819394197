// The strings here are CMS Article section types returned from the backend

enum CMSSectionType {
  CmsSectionType = 'CmsSectionType',
  CtaBanner = 'CtaBannerSection',
  Hero = 'HeroSection',
  Introduction = 'IntroductionSection',
  NavigationSection = 'NavigationSection',
  ProductTemplateCarousel = 'ProductTemplateCarouselSection',
  TextAndImage = 'TextAndImageSection',
  VideoHero = 'VideoHeroSection',
}

export default CMSSectionType

export type CMSSectionKey = keyof typeof CMSSectionType
export type CMSSectionValue = (typeof CMSSectionType)[CMSSectionKey]

// Map of the enum values to the enum keys
export const MAP_CMS_TYPE = Object.keys(CMSSectionType).reduce((acc, key) => {
  const k = key as CMSSectionKey
  acc[CMSSectionType[k]] = k
  return acc
}, {} as Record<CMSSectionValue, CMSSectionKey>)
